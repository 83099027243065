import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VCard,
    { class: _vm.gs.isMobile() ? "" : "dxa_modal" },
    [
      _c(VCardTitle, { staticClass: "mb-2" }, [
        _c("h4", { staticClass: "dxa_modal_title h4" }, [
          _vm._v(_vm._s(_vm.cardTitle)),
        ]),
      ]),
      _c(
        VCardText,
        { attrs: { "data-test": "VehicleRequest:AddUserInterestModal:form" } },
        [
          _c(
            VForm,
            {
              ref: "form",
              on: {
                submit: function ($event) {
                  $event.stopPropagation()
                  $event.preventDefault()
                  return _vm.submit()
                },
              },
            },
            [
              _c(VCombobox, {
                attrs: {
                  "data-test": "Vehicle:AddUserInterestModal:SelectUser",
                  disabled: _vm.edit,
                  items: _vm.users,
                  rules: [_vm.required],
                  "item-text": "Email",
                  "item-value": "Id",
                  label: _vm.$tc("user", 1),
                },
                on: { change: _vm.handleChangeUser },
                model: {
                  value: _vm.userSelected,
                  callback: function ($$v) {
                    _vm.userSelected = $$v
                  },
                  expression: "userSelected",
                },
              }),
              _c(VCombobox, {
                attrs: {
                  "data-test": "Vehicle:AddUserInterestModal:SelectCompany",
                  disabled: _vm.edit,
                  items: _vm.companies,
                  rules: [_vm.required],
                  "item-text": "Name",
                  "item-value": "Id",
                  label: _vm.$tc("company", 1),
                },
                model: {
                  value: _vm.companySelected,
                  callback: function ($$v) {
                    _vm.companySelected = $$v
                  },
                  expression: "companySelected",
                },
              }),
              _c(VCombobox, {
                attrs: {
                  "data-test": "Vehicle:AddUserInterestModal:SelectVehicle",
                  items: _vm.vehicles.CompanyInvestmentVehicles,
                  "item-text": "InvestmentVehicle.Name",
                  "item-value": "InvestmentVehicle.Id",
                  label: _vm.$t("vehicle_title_single"),
                },
                model: {
                  value: _vm.vehicleSelect,
                  callback: function ($$v) {
                    _vm.vehicleSelect = $$v
                  },
                  expression: "vehicleSelect",
                },
              }),
              _c(VCombobox, {
                attrs: {
                  "data-test": "Vehicle:AddUserInterestModal:SelectStatus",
                  disabled: _vm.edit,
                  items: _vm.StatusOptions,
                  rules: [_vm.requiredNumber],
                  "item-text": "Text",
                  "item-value": "Value",
                  label: _vm.$t("status"),
                },
                model: {
                  value: _vm.statusSelected,
                  callback: function ($$v) {
                    _vm.statusSelected = $$v
                  },
                  expression: "statusSelected",
                },
              }),
              _c(
                "div",
                [
                  _c(VTextField, {
                    staticClass: "pt-0",
                    attrs: {
                      label: _vm.$t("status_description"),
                      counter: "280",
                      rules: [_vm.rulesMaxStatusDescription],
                    },
                    model: {
                      value: _vm.interest.StatusDescription,
                      callback: function ($$v) {
                        _vm.$set(_vm.interest, "StatusDescription", $$v)
                      },
                      expression: "interest.StatusDescription",
                    },
                  }),
                ],
                1
              ),
              _c(VSelect, {
                attrs: {
                  "data-test": "Vehicle:AddUserInterestModal:InvestIntended",
                  rules: [_vm.required],
                  items: _vm.investedIntendedOptions,
                  "item-text": "tradingName",
                  "item-value": "id",
                  label: _vm.$t("investment_desire"),
                  dense: "",
                },
                model: {
                  value: _vm.interest.InvestorInvestIntendedPersonId,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.interest,
                      "InvestorInvestIntendedPersonId",
                      $$v
                    )
                  },
                  expression: "interest.InvestorInvestIntendedPersonId",
                },
              }),
              _c(VSelect, {
                attrs: {
                  rules: [_vm.required],
                  items: _vm.currencyOptions,
                  "item-text": "text",
                  "item-value": "value",
                  label: _vm.$t("currency"),
                  dense: "",
                  "data-test": "Vehicle:AddUserInterestModal:Currency",
                },
                model: {
                  value: _vm.interest.Currency,
                  callback: function ($$v) {
                    _vm.$set(_vm.interest, "Currency", $$v)
                  },
                  expression: "interest.Currency",
                },
              }),
              _c(VTextField, {
                staticClass: "pt-0",
                attrs: {
                  "data-test": "Vehicle:AddUserInterestModal:SelectValue",
                  rules: [_vm.required],
                  label: _vm.$t("value"),
                  prefix: _vm.getCurrencyPrefixData(_vm.interest.Currency),
                  type: "number",
                },
                model: {
                  value: _vm.interest.Value,
                  callback: function ($$v) {
                    _vm.$set(_vm.interest, "Value", $$v)
                  },
                  expression: "interest.Value",
                },
              }),
              _c(
                VCardActions,
                { staticClass: "pl-0 dxa_modal_actions" },
                [
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnSuccess",
                      attrs: {
                        "data-test": "Vehicle:AddUserInterestModal:BtnSave",
                        color: "primary",
                        "min-width": "100",
                        loading: _vm.loading,
                        type: "submit",
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("save")))]
                  ),
                  _c(
                    VBtn,
                    {
                      staticClass: "dxa_modal_btnError",
                      attrs: { color: "red", "min-width": "100" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close", null)
                        },
                      },
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("cancel")) + " ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.error
            ? _c(
                VAlert,
                {
                  staticClass: "ma-2",
                  attrs: {
                    type: "error",
                    "data-test": "Vehicle:AddUserInterestModal:Error",
                  },
                },
                [_vm._v(_vm._s(_vm.$t(_vm.error)))]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }